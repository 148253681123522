import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";
import { FiCheck } from "react-icons/fi";

export default function ExpressionCalculationMenu({
  data,
  title,
  value,
  setValue,
}: any) {
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState(null);

  useEffect(() => {
    if (data.length && value) {
      const filtered = data.filter((item: any) => item.value === value);
      setLabel(filtered[0].label);
    }

    if (value === null) {
      setLabel(null);
    }

  }, [data, value, title]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <div className="w-[200px] capitalize text-sm whitespace-nowrap h-9 px-4 py-2 justify-between cursor-pointer inline-flex items-center rounded-md border-2 border-slate bg-black">
          {label ? label : `Select ${title}`}
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[240px] p-0 border-slate bg-black command">
        <Command className="bg-dark border-slate max-h-[47vh] h-full">
          <CommandInput
            placeholder={`Search ${title}`}
            className="bg-dark border-b-slate"
          />
          <CommandEmpty>No item found.</CommandEmpty>
          <CommandGroup className="overflow-y-auto">
            {data.map((item: any) => (
              <CommandItem
                key={item.value}
                onSelect={(currentValue) => {
                  setValue(currentValue === value ? "" : item.value);
                  setLabel(item.label);
                  setOpen(false);
                }}
                className="cursor-pointer"
              >
                <FiCheck
                  className={cn(
                    "mr-2 h-4 w-4",
                    value === item ? "opacity-100" : "opacity-0"
                  )}
                />
                {item.label}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
