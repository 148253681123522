import Widget from "../../../components/DynamicWidgets/Widget";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import classNames from "utilities/ClassNames";
import { FiPlus } from "react-icons/fi";
import useApp from "hooks/useApp";
import { WidgetProps } from "./domain/types";
import { findMissingPosition } from "./config/missingWidget";
import { cn } from "@/lib/utils";
import useScreen from "hooks/useScreen";

export default function WidgetGrid({ type }: { type: number }) {
  const [lastWidget, setLastWidget] = useState<any>(null);
  const { dashboardWidgets, updateDashboardWidgets } = useApp();

  useEffect(() => {
    if (dashboardWidgets.widget1.length < 5) {
      const p = findMissingPosition(dashboardWidgets.widget1);
      const data = [...dashboardWidgets.widget1,
      { id: uuidv4(), tabs: [], position: p },
      ];
      updateDashboardWidgets({ ...dashboardWidgets, widget1: data });
    }
    if (dashboardWidgets.widget2.length < 5) {
      const p = findMissingPosition(dashboardWidgets.widget2);
      const data = [...dashboardWidgets.widget2,
      { id: uuidv4(), tabs: [], position: p },
      ];
      updateDashboardWidgets({ ...dashboardWidgets, widget2: data });
    }
  }, [dashboardWidgets]);

  useEffect(() => {
    if (dashboardWidgets.widget3) {
      setLastWidget(
        dashboardWidgets.widget3[dashboardWidgets.widget3.length - 1]
      );
    }
  }, [dashboardWidgets.widget3]);




  const handleNewWidget = (pos: number) => {
    const data = [
      ...dashboardWidgets.widget3,
      { id: uuidv4(), tabs: [], position: pos },
    ];
    updateDashboardWidgets({ ...dashboardWidgets, widget3: data });
  };

  const generateWidgets = (type: number, widgets: WidgetProps[]) => (
    <div className={cn("pb-10 2xl:pb-3", "grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-rows-2 gap-2 pt-2 pl-3 w-full h-full [grid-auto-rows:1fr]")}>
      {widgets
        .sort((a: WidgetProps, b: WidgetProps) => a.position - b.position)
        .map((widget: WidgetProps) => (
          <Widget key={widget.id} widget={widget} widgets={widgets} type={type} />
        ))}
    </div>
  );

  return (
    <>
      {type === 1 && generateWidgets(type, dashboardWidgets.widget1)}
      {type === 2 && generateWidgets(type, dashboardWidgets.widget2)}
      {type === 3 && <div className={cn("pb-10", "grid grid-cols-2 xl:grid-cols-3 2xl:grid-rows-2 gap-2 pt-2 pl-3  2xl:pb-3 w-full h-full")}>
        {dashboardWidgets.widget3 &&
          lastWidget &&
          dashboardWidgets.widget3.length > 0 &&
          dashboardWidgets.widget3
            .sort((a: WidgetProps, b: WidgetProps) => a.position - b.position)
            .map((widget: WidgetProps) => (
              <Widget
                key={widget.id}
                widgets={dashboardWidgets.widget3}
                widget={widget}
                type={type}
              />
            ))}
        {!dashboardWidgets.widget3 ||
          (dashboardWidgets.widget3 && dashboardWidgets.widget3.length < 6 && (
            <div
              className={classNames(
                dashboardWidgets.widget3.length > 0
                  ? "bg-transparent"
                  : "bg-dark",
                "w-widgets h-[400px] grid place-content-center rounded-md p-3"
              )}
            >
              <div
                className="flex flex-col gap-3 justify-center items-center cursor-pointer hover:text-green duration-300"
                onClick={() =>
                  handleNewWidget(
                    dashboardWidgets.widget3.length
                      ? parseInt(lastWidget?.position + 1)
                      : 1
                  )
                }
              >
                <FiPlus size={50} />
                Add Widget
              </div>
            </div>
          ))}
      </div>}
    </>

  );
}
