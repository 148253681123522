import BorderGradient from "components/shared/BorderGradient/BorderGradient";
import { useEffect, useState, useMemo } from "react";
import { FiX } from "react-icons/fi";
import TableManager from "components/TableManager/TableManager";
import Debounce from "utilities/Debounce";
import DataTable from "components/DataTable/DataTable";
import { getAllTags, mapEquipmentTag } from "api/tags";
import { mapAssetsDocs } from "api/docs";
import { getAllUniqueFiles } from "api/fileManager";
import Loader from "components/shared/Loader";
import processDocsData from "pages/all-assets/utils/processDocsData";

const MappingModal = ({ tag, page, listing, setSelectedTag, refresh }: any) => {
  const [debouncedKeyword, setDebouncedKeyword] = useState('');
  const [keyword, setKeyword] = useState('');
  const [data, setData] = useState([]);
  const [mappedTags, setMappedTags] = useState<any>({
    added: [],
    removed: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  let selectedEquipment: any;
  if (page === 'tags') {
    selectedEquipment = listing.selectedEquipment;
  }
  if (page === 'docs') {
    if (window.location.pathname === "/3d-dashboard") {
      selectedEquipment = { id: listing.selectedEquipmentId || listing.selectedUnitId || listing.selectedTrainId };
    }
    else {
      selectedEquipment = tag.parent;
    }
  }

  Debounce(() => setDebouncedKeyword(keyword), [keyword], 200);

  const rows = useMemo(() => {
    let items = processDocsData(data);
    const keyword = debouncedKeyword.toString().toLowerCase();
    if (keyword !== "") {
      if (page === 'tags') {
        items = data?.filter(
          (item: any) =>
            item.name.toLowerCase().includes(keyword) ||
            item.name.toLowerCase() === keyword
        );
      }
      if (page === 'docs') {
        items = data?.filter(
          (item: any) =>
            item.fileName.toLowerCase().includes(keyword) ||
            item.fileName.toLowerCase() === keyword
        );
      }
    }
    return items;
  }, [data, debouncedKeyword]);

  const onClickHandler = () => {
  };

  useEffect(() => {
    if (page === 'tags') {
      const fetchTags = async () => {
        const response = await getAllTags();
        if (response.status === 200) {
          setData(response.data);
          setIsLoading(false);
        }
      };
      fetchTags();
    }
    if (page === 'docs') {
      const fetchDocs = async () => {
        const response = await getAllUniqueFiles('asset_doc');
        if (response.status === 200) {
          setData(response.data);
          setIsLoading(false);
        }
      };
      fetchDocs();
    }
  }, []);

  const submitAction = async () => {
    setIsLoading(true);
    const { added, removed } = mappedTags;
    if (page === 'tags') {
      const addedTags = added.map((tag: number) => ({
        equipmentId: selectedEquipment.id,
        tagId: tag,
        isMapped: true,
      }));
      const removedTags = removed.map((tag: number) => ({
        equipmentId: selectedEquipment.id,
        tagId: tag,
        isMapped: false,
      }));
      const response = await mapEquipmentTag([...addedTags, ...removedTags]);
      setIsLoading(false);
      if (response.status === 200) {
        refresh();
        setSelectedTag({ action: "", value: null });
      }
    }
    if (page === 'docs') {
      const addedTags = added.map((tag: number) => ({
        fileId: tag,
        isMapped: true,
        ...(selectedEquipment.unitId && { EquipmentId: selectedEquipment.id }),
        ...(selectedEquipment.trainId && { UnitId: selectedEquipment.id }),
        ...(selectedEquipment.plantId && { TrainId: selectedEquipment.id })
      }));
      const removedTags = removed.map((tag: number) => ({
        fileId: tag,
        isMapped: false,
        ...(selectedEquipment.unitId && { EquipmentId: selectedEquipment.id }),
        ...(selectedEquipment.trainId && { UnitId: selectedEquipment.id }),
        ...(selectedEquipment.plantId && { TrainId: selectedEquipment.id })
      }));
      const response = await mapAssetsDocs([...addedTags, ...removedTags]);
      setIsLoading(false);
      if (response.status === 200) {
        refresh();
        setSelectedTag({ action: "", value: null });
      }
    }

  };

  const mapAll = () => {
    const ids = rows.map((single: any) => single.id);
    setMappedTags({
      added: ids,
      removed: [],
    });
  };

  return (
    <div className="z-10 h-full w-full">
      <BorderGradient className="w-full h-max">
        <div className="w-full p-3 bg-dark rounded-md h-[65vh] overflow-hidden">
          <div className="flex flex-wrap justify-between pb-5">
            <h1>{page==='docs'?"Map Document to":"Map Tag to"}
              <span className="text-green font-bold"> {selectedEquipment.name}</span>
            </h1>
            <button
              className="cursor-pointer bg-green rounded-md p-1"
              onClick={() =>
                setSelectedTag({ action: "", value: null })
              }
            >
              <FiX size={20} />
            </button>
          </div>
          <TableManager setKeyword={setKeyword} type="mapping" btnAction={submitAction} secondaryBtnAction={page === 'docs' && mapAll} searchRightSide={false} />
          {data.length > 0 && !isLoading &&
            <DataTable
              type={page === 'tags' ? "tags" : "mappedDocs"}
              hasPins={false}
              special={true}
              specialItems={tag}
              hasActions={false}
              rows={rows}
              setMappedTags={setMappedTags}
              mappedTags={mappedTags}
              onClickHandler={onClickHandler}
              onActionClickHandler={onClickHandler}
              setSelectedPage={setSelectedTag}
              selectedPage={selectedEquipment}
            />
          }
          {isLoading &&
            <div className="w-full h-[80%] flex justify-center items-center">
              <span className="-mt-4"><Loader /></span>
            </div>
          }
        </div>
      </BorderGradient>
    </div>
  );
};

export default MappingModal;